import { graphql } from "gatsby"
import React from "react"
import LinkButton from "../components/LinkButton"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { notFound, image, content, title } from "../styles/notfound.module.css"

export default function NotFound({ data }) {
  const notFoundImage = getImage(data.file)

  return (
    <div className={notFound}>
      <GatsbyImage
        className={image}
        image={notFoundImage}
        alt="Page not found image"
      />
      <div className={content}>
        <h1 className={title}>
          Oops, <br />
          You got lost.
        </h1>
        <LinkButton to="/">Head over to the homepage</LinkButton>
      </div>
    </div>
  )
}

export const queyr = graphql`
  query NotFoundImage {
    file(name: { eq: "404" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`
